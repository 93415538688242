







































import UploadAttachmentsDialog from './UploadAttachmentsDialog.vue';
import { Attachment, Patient } from '@/models';
import { PropType, defineComponent, onMounted, ref } from '@/plugins/composition';

export default defineComponent({
  name: 'PatientAttachmentsTable',
  components: {
    UploadAttachmentsDialog,
  },
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },

  setup(props) {
    const headers = [
      { text: 'Type', value: 'type' },
      { text: 'File Name', value: 'filename' },
      { text: 'File Type', value: 'extension' },
      { text: 'Uploaded', value: 'uploaded' },
      { text: 'Actions', value: 'actions' },
    ];
    const attachments = ref<Attachment[]>([]);

    const loadAttachments = async () => {
      attachments.value = (await props.patient.attachments().getAll()) ?? [];
    };

    const download = async (item: Attachment) => {
      await item.download();
    };

    onMounted(() => loadAttachments());

    return {
      headers,
      attachments,
      download,
      loadAttachments,
    };
  },
});
