















































import { Attachment, Patient } from '@/models';
import { PropType, defineComponent, onMounted, ref } from '@/plugins/composition';

type File = {
  file: Blob;
  name: string;
  type: string;
};

export default defineComponent({
  name: 'UploadAttachmentsDialog',
  props: {
    attachable: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const files = ref<File[]>([]);
    const filesNumber = ref(1);
    const valid = ref(false);
    const fileRules = [
      (v: File) => !!v || 'File is required',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => !value || value.size < 8000000 || 'File size must be less than 8 MB!',
    ];
    const loading = ref(false);

    const addFile = () => {
      const emptyFile = {
        file: new Blob(),
        name: '',
        type: 'Attachment',
      };

      files.value.push(emptyFile);
    };

    onMounted(() => addFile());

    const submit = async () => {
      loading.value = true;
      const formData = new FormData();
      files.value.forEach((fileObject, i) => {
        formData.append('files[' + i + '][file]', fileObject.file);
        formData.append('files[' + i + '][name]', fileObject.name);
        formData.append('files[' + i + '][type]', fileObject.type);
      });

      formData.append('attachable_uuid', props.attachable.uuid);
      const response = await upload(formData);
      if (response) {
        reset();
        emit('input', response);
      }
      loading.value = false;
    };

    const reset = () => {
      files.value = [];
      addFile();
      dialog.value = false;
    };

    const upload = async (formData: FormData) => {
      return await Attachment.upload(formData, props.attachable);
    };

    return {
      dialog,
      addFile,
      files,
      filesNumber,
      valid,
      fileRules,
      loading,
      submit,
    };
  },
});
