var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.attachments,"calculate-widths":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('upload-attachments-dialog',{attrs:{"attachable":_vm.patient},on:{"input":_vm.loadAttachments},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"color":"info"}},'v-btn',attrs,false),on),[_vm._v("Upload")])]}}])})],1)]},proxy:true},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.filename)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.type)+" ")]}},{key:"item.extension",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.extension)+" ")]}},{key:"item.uploaded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2",attrs:{"color":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.download(item)}}},[_vm._v(" Download ")])]}}],null,true)}),_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }